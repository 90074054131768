import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

// Set document title and remove React favicon
document.title = 'Amazon Deals Finder | Best UK Deals';
const favicon = document.querySelector('link[rel="icon"]');
if (favicon) {
    favicon.remove();
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
